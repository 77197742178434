/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import PopinConnexion from './components/PopinConnexion';
import ContactForm from './components/ContactForm';
import Cnil from './components/Cnil';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


if ($('#filter').length) {

    const filter = new Vue({
        el: '#filter',
        data: {
            members: '-membres-actifs',
        },
        mounted() {
            $('.members__item').hide();
            $('.' + this.members).fadeIn();
        },

        methods: {
            showMembers(member) {
                this.members = member;

                $('.members__item').hide();
                $('.' + member).fadeIn();

            }
        }
    });
}

const connexion = new Vue({
    el: '#connexion',
    data: {
        showLogin: false,
    },
    components: {
        'popin-connexion': PopinConnexion,
    },
});


if ($('#cnil').length) {

    const cnil = new Vue({
        el: '#cnil',
        components: {
            'cnil': Cnil,
        }
    });
}

if ($('#contact').length) {
    const contact = new Vue({
        el: '#contact',
        components: {
            'contact-form': ContactForm,
        }
    });

}
const navbar = new Vue({
    el: '#main__nav',
    data: {
        active: null,
        forced: false,
        mobile: {
            nav: 0,
        }
    },
    mounted: function () {
        let nav = document.querySelector('#main__nav');
        nav.style.display = "block";

        this.forced = menuShow ? true : false;

    },
    methods: {
        setActive(item) {

            if (this.active === item)
                this.active = null;
            else
                this.active = item;

        },
        showLogin() {
            connexion.showLogin = true;
        }
    }
});


if ($('.partners').length) {

    const partner = new Vue({
        el: '.partners',
        data: {
            translate: 0,
            min: 0,
            max: 0,
            auto: true,
            dist: 100,
            right: true,
            interval: null,
            timeout: null,
            started: false,
        },
        mounted() {
            this.start();
            let items = $('.partners__item.-i0');
            this.max = items.length * items.outerWidth() - $('.partners__list').outerWidth() + 20;
        },
        methods: {
            start() {
                if (this.started)
                    return;

                this.started = true;

                this.interval = setInterval(() => {
                    if (this.right) {
                        if (this.translate <= -this.max) this.right = false;
                        this.translate = this.translate - this.dist;
                    } else {
                        if (this.translate >= 0) this.right = true;
                        this.translate = this.translate + this.dist;
                    }

                    document.querySelector('.partners__container').style.transform = `translateX(${this.translate}px)`
                }, 2000)

            },
            stop() {

                this.started = false;
                clearInterval(this.interval);
                clearTimeout(this.timeout);

                this.timeout = setTimeout(() => {
                    this.start();
                }, 5000);
            },
            moveRight() {
                this.stop();
                this.translate = this.translate - $('.partners__item').outerWidth();

                if (this.translate <= -this.max)
                    this.translate = -this.max;

                document.querySelector('.partners__container').style.transform = 'translateX(' + this.translate + 'px)';


            },
            moveLeft() {
                this.stop();
                this.translate = this.translate + $('.partners__item').outerWidth();

                if (this.translate >= 0)
                    this.translate = 0;

                document.querySelector('.partners__container').style.transform = 'translateX(' + this.translate + 'px)';


            },
        }
    })
}

$(function () {
    if ($('.post').length) {
        let arrangePosts = function () {
            let height = 0;
            let margin = 0;
            let oh = 0;

            let posts = $('.post__item');

            $('.post__title').removeAttr('style');
            $('.post__description').removeAttr('style');

            for (let i = 0; i < posts.length; i++) {
                oh = $(posts[i]).height();

                if (oh > height)
                    height = oh;
            }

            for (let n = 0; n < posts.length; n++) {
                margin = height - $(posts[n]).outerHeight() + 40;
                $(posts[n]).css('opacity', 1);
                $(posts[n]).find('.post__content').css('height', height);
            }

        }
        let res;
        $(window).resize(function () {
            clearTimeout(res);
            res = setTimeout(function () {
                arrangePosts();
            }, 500)
        }).resize()

    }

    if ($('.partners__list').length) {

        let items = $('.partners__item');
        let items0 = $('.partners__item.-i0');
        let items1 = $('.partners__item.-i1');
        let width = items.outerWidth();

        for (let i = 0; i < items0.length; i++) {
            $(items0[i]).css('left', i * width);
        }

        for (let i = 0; i < items1.length; i++) {
            $(items1[i]).css('left', i * width);
        }

    }

    $('.menu-mobile').on('click', function () {

        navbar.mobile.nav = !navbar.mobile.nav;
        $(this).find('span').toggleClass('-active');

    });


    if ($('.toForm').length) {

        $('.toForm').on('click', function (e) {
            e.preventDefault();

            let ft = $('#form').offset().top;

            $('html, body').animate({scrollTop: ft - 90}, 300);

        })

    }


    if ($('.panel.panel-default').length) {


        $(window)
            .resize(function () {
                let hh = $('#main__nav').outerHeight();
                let hf = $('.footer').outerHeight();

                let hp = $(window).outerHeight() - (hh + hf) + 'px';

                $('.window').css('height', hp);
            })
            .resize()

    }

    $(document).scroll(function () {

        if ($(window).width() < 800) return;

        let scroll = $(document).scrollTop();

        if (scroll > 100) {
            $('.navbar, .navbar__sublist').addClass('-small');
        } else {
            $('.navbar, .navbar__sublist').removeClass('-small');
        }

    }).scroll();
});

