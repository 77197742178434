<template>
    <div class="cnil">
        <div class="cnil__close" @click="close">X</div>
        <p class="cnil__content">
            En poursuivant votre navigation sur ce site, vous acceptez l'utilisation des cookies pour vous proposer des
            contenus adaptés à vos centres d'intérêts.<br>
            <a v-bind:href="link">Pour plus d'informations, gérer ou modifier vos paramètres de cookies, cliquez-ici</a>
        </p>
    </div>
</template>

<script>
    export default {
        name: "Cnil",
        props: ['action', 'link'],
        methods: {
            close() {
                axios.post(this.action)
                    .then((res) => {
                        $('.cnil').remove();
                    });
            }
        }
    }
</script>

<style lang="scss" scoped>

    .cnil {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, .9);
        padding: 20px;
        z-index: 999;

        &__close {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            z-index: 999;
            color: white;
        }

        &__content {
            margin: 0;
            color: white;
            text-align: center;
            font-size: 14px;

            a {
                color: white;
            }
        }
    }

</style>
