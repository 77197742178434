<template>
    <div>
        <form id="form" v-bind:action="action" method="post" @submit.prevent="submit()" v-if="showForm">
            <input type="hidden" name="_token" v-bind:value="token">

            <div class="form__group">
                <div class="form__input -demi">
                    <label for="civ" class="form__label">
                        Civilité
                    </label>
                    <select name="civilite" v-model="civ" id="civ">
                        <option value="M">Monsieur</option>
                        <option value="Mme">Madame</option>
                    </select>
                </div>
            </div>
            <div class="form__group">
                <div class="form__input -demi">
                    <label for="last_name" class="form__label">
                        Nom
                    </label>
                    <input type="text" name="nom" v-model="last_name" id="last_name">
                </div>

                <div class="form__input -demi">
                    <label for="first_name" class="form__label">
                        Prénom
                    </label>
                    <input type="text" name="prenom" v-model="first_name" id="first_name">
                </div>
            </div>

            <div class="form__input">
                <label for="email" class="form__label">
                    email
                </label>
                <input type="email" name="email" v-model="email" id="email">
            </div>

            <div class="form__input">
                <label for="demande" class="form__label">
                    Demande
                </label>
                <textarea v-model="demande" name="demande" id="demande"></textarea>
            </div>
            <div class="g-recaptcha"
                 data-sitekey="6LdtK10UAAAAADoazpEYvbHFT1w6wxUgL5pTzLR9"></div>
            <button type="submit">Envoyer</button>
        </form>

        <div v-if="!showForm">
            <p class="success">Votre demande à bien été prise en compte !</p>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ContactForm",
        data() {
            return {
                civ: 'M',
                last_name: null,
                first_name: null,
                email: null,
                demande: null,
                captchaRes: null,
                showForm: true,
            }
        },
        props: ['token', 'action', 'check'],
        methods: {
            submit() {

                let vm = this;

                axios.post(this.action, $('#form').serialize())
                    .then((res) => {
                        if (res.data === 'success') vm.showForm = false;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }
</script>

<style scoped>
    p.success {
        text-align: center;
        font-weight: bold;
    }
</style>