<template>
    <div class="connexion__popin" v-if="$parent.showLogin || errormail || errorpassword || middleware" @click="close($event)">

        <div class="connexion__popin__container">
            <div class="connexion__popin__head">
                <div class="connexion__popin__head__icon"></div>
            </div>
            <div class="connexion__popin__content">
                <p class="connexion__popin__title">
                    Connexion
                </p>
                <p class="connexion__popin__paragraph">
                    Renseignez votre identifiant et votre mot de passe pour accéder à votre espace membre APECI
                </p>

                <form v-bind:action="connexion" method="post">
                    <input type="hidden" name="_token" v-bind:value="token">
                    <input type="text" name="email" class="connexion__popin__input" placeholder="email">
                    <input type="password" name="password" class="connexion__popin__input" placeholder="mot de passe">
                    <button class="connexion__popin__submit" type="submit">Login</button>
                    <a class="connexion__popin__forget" v-bind:href="password">
                        <span>i</span> <i>Mot de passe oublié</i>
                    </a>

                    <p v-if="errormail" class="connexion__popin__error">{{ errormail }}</p>
                    <p v-if="errorpassword" class="connexion__popin__error">{{ errorpassword }}</p>

                </form>

            </div>
            <div class="connexion__popin__social">
                <a href="https://www.linkedin.com/company/association-apeci" target="_blank" class="link --rounded --small --linkedin">
                    <span></span>
                </a>
                <a href="https://twitter.com/apeciweb" target="_blank" class="link --rounded --small --twitter">
                    <span></span>
                </a>
                <a href="" class="link --rounded --small --mail">
                    <span></span>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "PopinConnexion",
        props: ['token', 'connexion', 'password', 'errormail', 'errorpassword', 'middleware'],
        methods: {
            close(event) {
                if(event.target === event.currentTarget) {
                    this.$parent.showLogin = false;
                    this.errormail = null;
                    this.errorpassword = null;
                    this.middleware = null;
                }
            }
        }
    }
</script>

<style scoped>

    .connexion__popin__forget span {
        font-variant: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #becfd7;
        display: inline-block;
        text-align: center;
        line-height: 17px;
        margin-right: 8px;
        color: #becfd7;
    }

    .connexion__popin__forget i {
        font-size: 10px;
        color: #3d4141;
        text-decoration: underline;
        font-style: normal;
        display: inline-block;
        vertical-align:middle;
        text-transform: uppercase;
        position: relative;
        top: -2px;
    }

</style>
